<template>
    <div class="az-body">
        <div class="az-error-wrapper">
            <h1 class="tx-danger">Failed</h1>
            <h2>Oopps. Something wrong happens.</h2>
            <h6>Please try after sometimes.</h6>
            <p>You'll be redirected to the wallet page in {{ timerCount }} sec or <a href="javascript:void(0)" @click="toOrg()">Click Here</a></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "FailedUrl",
    data() {
        return {
            timerCount: 5
        }
    },
    watch: {
        timerCount: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--;
                        if (this.timerCount === 0) {
                            this.$router.push({name: 'myorg'});
                        }
                    }, 1000);
                }
            },
            immediate: true // This ensures the watcher is triggered upon creation
        }
    },
    methods: {
        toOrg() {
            this.$router.push({name: 'myorg'});
        }
    }
}
</script>

<style scoped>

</style>